<template>
    <div class="summary-student-status-info content-bg">
        <global-page-back
            :detailPageTitle="pageTitle"
            @handlerGlobalType="back"
        >
        </global-page-back>
        <expand-filter
            :close-width="960"
            :formData="formData"
            marginTop="10px"
            @clickBtn="clickBtn"
            @changeBtnFormType="changeBtnFormType"
            @dataChange="dataChange"
            @inputInput="handleFilterInput"
            @changeCascader="handleFilterCascader"
        >
        </expand-filter>
        <!-- 历史出勤列表 -->
        <table-data
            v-loading="loadingTable"
            ref="table"
            id="table"
            :config="table_config"
            :tableData="table_data"
        >   
            <!-- 历史出勤的slot -->
            <!-- 星期 -->
            <template v-slot:week="slotData">
                <div>{{getWeek(slotData.data.attendanceDate)}}</div>
            </template>
            <!-- 状态 -->
            <template v-slot:status="slotData">
                <el-link 
                    :type="getAttendancesStatusBycode(slotData.data.status)['type']"
                    :underline="false"
                    :style="{
                        cursor:'auto'
                    }"
                >
                    {{getAttendancesStatusBycode(slotData.data.status)['name']}}
                </el-link>
            </template>
            <!-- 修改记录的slot -->
            <!-- 修改前出勤状态 -->
            <template v-slot:beforeModifyStatus="slotData">
                <el-link 
                    :type="getAttendancesStatusBycode(slotData.data.beforeModifyStatus).type"
                    :underline="false"
                    :style="{
                        cursor:'auto'
                    }"
                >
                    {{getAttendancesStatusBycode(slotData.data.beforeModifyStatus).name}}
                </el-link>
            </template>
            <!-- 修改后出勤状态 -->
            <template v-slot:afterModifyStatus="slotData">
                <el-link 
                    :type="getAttendancesStatusBycode(slotData.data.afterModifyStatus).type"
                    :underline="false"
                    :style="{
                        cursor:'auto'
                    }"
                >
                    {{getAttendancesStatusBycode(slotData.data.afterModifyStatus).name}}
                </el-link>
            </template>
        </table-data>
        <!-- 分页器 -->
        <Pagination
            :total="total"
            :page.sync="pageQuery.pageNum"
            :limit.sync="pageQuery.pageRow"
            @pagination="getList()"
        />
    </div>
</template>

<script>
import {debounce,Pagination} from "common-local";
import GlobalPageBack from "@/components/scrollWrapper/Sub/GlobalPageBack/index.vue";
import ExpandFilter from "./Sub/ExpandFilter";
import TableData from "@/components/scrollWrapper/Sub/TableData";
import {mapState} from "vuex";
import { listToTree } from "@/libs/utils.js";
export default {
    components: {
        GlobalPageBack,
        ExpandFilter,
        TableData,
        Pagination
    },
    data() {
        const _this = this;
        return {
            treeDataOption: {
                type: "selectTree",
                label: "班级",
                value: "",
                placeholder: "请选择班级",
                show: true,
                defaultProps: {
                    children: "children",
                    label: "name",
                },
                dataList: [],
                showAlways: true,
                filter(data) {
                    return data.organType != 1;
                },
            },
            // 头部筛选
            formData: {
                data: [
                    {
                        type: "datePick",
                        label: "",
                        value: "",
                        startPlaceholder: "开始日期",
                        endPlaceholder: "结束日期",
                        key: "dateArr",
                        clearable:false,
                        pickerOptions: {
                            disabledDate(time) {
                                return time.getTime() > Date.now() || time.getTime() < _this.$moment().subtract(1, 'months').date(1).subtract(1, 'days').valueOf();
                            },
                        },
                    },
                ],
                btnList: [],
                btnFormType: false,
            },
            // 修改记录的筛选条件（formData以外的条件）
            changeFormData: [
                {   
                    key:"organId",
                    label: "",
                    placeholder: "班级",
                    type: "cascader",
                    value: [],
                    list: [],
                    cascaderProps: {
                        label: "name",
                        multiple: true,
                        value: "id",
                        // checkStrictly: true,
                        emitPath:false
                    },
                    filter(data) {
                        return data.organType != 1;
                    },
                },
                {
                    type: "input",
                    label: "",
                    value: "",
                    placeholder: "姓名",
                    key: "studentName",
                },
                {
                    type: "input",
                    label: "",
                    value: "",
                    placeholder: "学号",
                    key: "studentNo",
                },
                {
                    type: "input",
                    label: "",
                    value: "",
                    placeholder: "唯一号",
                    key: "studentCode",
                },
            ],
            changeBtnList: [
                {
                    type: "primary",
                    text: "查询",
                    fn: "primary",
                    auth: ["checkStudentStatus"]
                },
                {
                    type: "enquiry",
                    text: "重置",
                    fn: "reset",
                    auth: ["checkStudentStatus"]
                },
            ],
            loadingTable: false,
            table_config: {
                thead: [],
                check: false,
                height: "",
                rowkey: "id",
            },
            // 历史出勤表头
            historyThead: [
                {
                    label: "日期",
                    prop: "attendanceDate",
                    align: "center",
                },
                {
                    label: "星期",
                    prop: "week",
                    type: "slot",
                    slotName: "week",
                    align: "center",
                },
                {
                    label: "出勤状态",
                    prop: "status",
                    type: "slot",
                    slotName: "status",
                    align: "center",
                },
            ],
            // 修改记录表头
            changeThead: [
                {
                    label: "学生姓名",
                    prop: "studentName",
                    align: "center",
                },
                {
                    label: "学号",
                    prop: "studentNo",
                    align: "center",
                },
                {
                    label: "唯一号",
                    prop: "studentCode",
                    align: "center",
                },
                {
                    label: "年级",
                    prop: "gradeName",
                    align: "center",
                },
                {
                    label: "班级",
                    prop: "className",
                    align: "center",
                },
                {
                    label: "修改前出勤状态",
                    type: "slot",
                    slotName: "beforeModifyStatus",
                    align: "center",
                    labelWidth: "130px",
                },
                {
                    label: "修改后出勤状态",
                    type: "slot",
                    slotName: "afterModifyStatus",
                    align: "center",
                    labelWidth: "130px",
                },
                {
                    label: "修改人",
                    prop: "createBy",
                    align: "center",
                },
                {
                    label: "修改时间",
                    prop: "createTime",
                    align: "center",
                    labelWidth: "160px",
                },
                {
                    label: "修改原因",
                    prop: "updateReason",
                    align: "center",
                    type: "tooltipColumn",
                    labelWidth: "180px",
                },
            ],
            table_data: [],
            total: 0,
            pageQuery: {
                pageNum: 1,
                pageRow: 20,
            },
            studentInfo:{},
            type: 'history',
        };
    },
    computed: {
        ...mapState({
            schoolId: (state) => state.schoolId,
        }),
        pageTitle() {
            if(this.$route.query.type === 'history'){
                return `${this.studentInfo.studentName}的历史出勤`
            } else {
                return `修改记录`
            }
        },
    },
    async created(){
        this.type = this.$route.query.type;
        let studentInfoSave = localStorage.getItem('studentInfo');
        if(!studentInfoSave){
            // 暂存学生信息，防止刷新页面丢失数据
            localStorage.setItem('studentInfo',JSON.stringify(this.$route.params));   
        }
        this.studentInfo = JSON.parse(localStorage.getItem('studentInfo'));
        if(this.$route.query.type === 'change'){
            // 获取班级
            this.getOrg();
            this.formData.data.push(...this.changeFormData);
            // 筛选条件赋值
            this.formData.data.forEach((item) => {
                if(item.key == "organId") {
                    item.value.push(this.studentInfo.classId);
                } else if(item.key == "studentName") {
                    item.value = this.studentInfo.studentName;
                } else if(item.key == "studentNo") {
                    item.value = this.studentInfo.studentNo;
                } else if(item.key == "studentCode") {
                    item.value = this.studentInfo.studentCode;
                }
            })
            this.formData.btnList.push(...this.changeBtnList);
            this.table_config.thead.push(...this.changeThead);
            // 学生列表的级部、年级、班级的显示判断
            if(this.$store.state.gradeLevel == 7 || this.$store.state.gradeLevel == 6) {
                // 获取年级的列序号
                let gradeColumnIndex = this.table_config.thead.findIndex((item) => item.prop == "gradeName");
                // 7-级部在年级之前  6-级部在年级之后
                this.table_config.thead.splice(
                    this.$store.state.gradeLevel == 7 ? gradeColumnIndex : gradeColumnIndex+1,
                    0, 
                    {
                        label: "级部",
                        prop: "departmentName",
                        align: "center",
                    }
                )
            }
        } else {
            this.table_config.thead.push(...this.historyThead)
        }
    },
    mounted() {
        this.$eventDispatch("setGlobalPageType", "detail");
        this.$nextTick(_=>{
            this.table_config.height = document.body.clientHeight - this.$refs.table.$el.offsetTop - 140;
        })
        this.setDefaultTime();
        this.getList();
    },
    activated() {
        this.$eventDispatch("setGlobalPageType", "detail");
    },
    methods: {
        // 获取年级班级下拉
        getOrg() {
            this._fet(
                "/school/schoolOrgan/listAuthByCondition",
                {
                    attendanceDate: this.formData.date,
                    schoolId: this.schoolId,
                    jobScope: ["1", "2", "3"]
                },
            ).then((res) => {
                if (res.data.code === "200") {
                    const treeData = listToTree(res.data.data.list, {
                        parentKey: "parentOrg",
                    });
                    this.treeDataOption.dataList = treeData.filter(
                        (i) => i.organType !== "1",
                    );
                    let jsonList = JSON.stringify(this.treeDataOption.dataList);
                    let jsonData = jsonList.replace(
                        /\"children\":\[]/g,
                        '"children":null',
                    );
                    this.formData.data.forEach((item) => {
                        let keyAboutClassArr = ["organId"]
                        if (keyAboutClassArr.includes(item.key)) {
                            item.list = JSON.parse(jsonData);
                        }
                    });
                    
                } else {
                    this.$message.error("数据请求失败，请重试");
                }
            });
        },
        handleFilterInput:debounce(function(data){
            console.log(data);
            // 姓名、学号、唯一号与班级互斥
            let keyArr = ['studentName','studentNo','studentCode'];
            let classKey = "organId"
            if(keyArr.includes(data.key) && data.value) {
                    // 清空班级值
                    this.formData.data.forEach(formItem => {
                        if(formItem.key == classKey){
                            formItem.value = [];
                        }
                    })
                }
        },200),
        handleFilterCascader(data) {
            console.log(data);
            // 姓名、学号、唯一号与班级互斥
            let keyArr = ['studentName','studentNo','studentCode'];
            let classKey = "organId"
            if(data.data.key == classKey && data.data.value.length > 0){
                this.formData.data.forEach((item) => {
                    if(keyArr.includes(item.key)){
                        item.value = '';
                    }
                })
            }
        },
        clickBtn(type) {
            switch (type.item.fn) {
                case "primary": // 查询
                    this.pageQuery.pageNum = 1;
                    this.getList();
                    break;
                case "reset": // 重置
                    this.pageQuery.pageNum = 1;
                    this.formData.data.forEach((item) => {
                        if(item.key == "organId"){
                            item.value = [];
                        } else {
                            item.value = "";
                        }
                    });
                    this.setDefaultTime();
                    this.getList();
                    break;
            }
        },
        changeBtnFormType() {
            this.formData.btnFormType = !this.formData.btnFormType;
            this.$nextTick(() => {
                this.table_config.height = document.body.clientHeight - this.$refs.table.$el.offsetTop - 140;
            })
        },
        dataChange(data) {
            console.log(data, "筛选条件");
            data.forEach(item => {
                if(item.key == "dateArr"){
                    console.log(item.value, "时间");
                    // 历史出勤，没有查询按钮，修改时间直接触发检索
                    if(this.type==='history') this.getList()
                }
            });
        },
        back() {
            // 清除列表页传过来的学生信息
            localStorage.removeItem('studentInfo');
            console.log(this.$route, "路由信息");
            this.$eventDispatch("setBreadcrumbs", this.$route);
            this.$router.push({
                name: "SummaryStudentStatus",
            });
            sessionStorage.setItem("summaryStudentStatusRole", "student");
            this.$eventDispatch("setGlobalPageType", "list");
        },
        setDefaultTime() {
            let startDate,currentDate;
            // 历史出勤的默认时间是前一周
            // 修改记录的默认时间是当天
            if(this.type === "history"){
                startDate = this.$moment().subtract(7, 'days').format("YYYY-MM-DD")
                currentDate = this.$moment().subtract(1, 'days').format("YYYY-MM-DD");
            } else {
                startDate = this.$moment().format("YYYY-MM-DD");
                currentDate = this.$moment().format("YYYY-MM-DD");
            }
            this.formData.data.forEach((item) => {
                if (item.key === "dateArr") {
                    item.value = [startDate, currentDate];
                }
            });
        },
        // 获取表格数据
        async getList(){
            let dateObj = this.formData.data.filter(item => item.key === "dateArr")[0]
            let param = {
                ...this.pageQuery,
                startDate:dateObj.value[0],
                endDate:dateObj.value[1],
                schoolId: this.schoolId,
            }
            this.loadingTable = true;
            if(this.type == "history"){
                param.studentId = this.studentInfo.studentId;
                // 历史出勤
                await this._fet('/school/schoolStudentAttendance/historyAttendanceByPage',param).then((res) => {
                    if(res.data.code === "200") {
                        this.table_data = res.data.data.list;
                        this.total = res.data.data.totalCount;
                    }
                })
            } else {
                // 修改记录
                this.formData.data.forEach((item) => {
                    if(item.key == "organId") {
                        param[item.key] = item.value.join(',');
                    } else {
                        param[item.key] = item.value;
                    } 
                });
                param.classId = param.organId;
                delete param.dateArr;
                await this._fet('/school/modifiedSchoolStudentAttendanceRecord/listByPage',param).then((res) => {
                    if(res.data.code === "200") {
                        this.table_data = res.data.data.list;
                        this.total = res.data.data.totalCount;
                    }
                })
            }
            this.loadingTable = false;
        },
        // 根据日期获取星期几
        getWeek(date) {
            let week = new Date(date).getDay();
            let weekArr = [
                "星期日",
                "星期一",
                "星期二",
                "星期三",
                "星期四",
                "星期五",
                "星期六",
            ];
            return weekArr[week];
        },
        // 根据标识获取出勤状态
        getAttendancesStatusBycode(code) {
            let stausArr = [
                {
                    code: "3",
                    name: "未到",
                    type: "danger"
                },
                {
                    code: "1",
                    name: "到校",
                    type: "success"
                },
                {
                    code: "2",
                    name: "请假",
                    type: "warning"
                },
            ];
            let status = stausArr.find((item) => item.code === code);
            return status;
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .el-link.el-link--warning {
            color:#c600fb
        } 
</style>
